import React from "react";
import TokenCheck from "../components/utils/TokenCheck";
import AdminCheck from "../components/utils/AdminCheck";
import AdminNav from "../components/admin/AdminNav";

const AdminN = () => {
    return (
        <div className='rf-home'>
            <TokenCheck>
                <AdminCheck>
                    <AdminNav />
                </AdminCheck>
            </TokenCheck>
        </div>
    );
};

export default AdminN;
