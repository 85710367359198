import React from "react";
import "../../styling/App.css";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";

const ImgContainer = () => {
    return (
        <div className='rf-image-container'>
            <Card className='rf-image-container-card'>
                <Typography variant='h4' className='rf-title'>
                    This is
                </Typography>
                <Typography variant='h2' className='rf-title' style={{ borderTop: "2px solid white" }}>
                    Resurgence
                </Typography>
                <Typography variant='h2' className='rf-title'>
                    Fitness
                </Typography>
            </Card>
        </div>
    );
};

export default ImgContainer;
