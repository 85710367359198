import React, { useState } from "react";
import axios from "axios";
import { Box, Button, TextField, Typography, Alert, CircularProgress } from "@mui/material";
import "../../styling/App.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const SignupForm = () => {
    const [firstname, setFirstname] = useState("");
    const [surname, setSurname] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [signupStatus, setSignupStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!firstname || !surname || !username || !password || !email) {
            setSignupStatus("Please fill out all fields.");
            return;
        }

        setLoading(true);

        try {
            axios.defaults.baseURL = "https://resurgence-api.xyz";
            const signupData = {
                firstname,
                surname,
                username,
                password,
                email,
            };

            // Use the api instance to make the POST request
            var response = await axios.post("/users", signupData);
            setSignupStatus("Signup successful!");
            navigate("/login");
            window.location.reload(); // Refresh the page
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.error.includes("Username")) {
                        setUsernameError(true);
                    }
                    // Check if the email already exists
                    if (error.response.data.error.includes("Email")) {
                        setEmailError(true);
                    }
                    setSignupStatus(error.response.data.error);
                } else {
                    setSignupStatus(error.response.data.error);
                }
            } else if (error.request) {
                setSignupStatus("No response from the server. Please try again later.");
            } else {
                setSignupStatus("An error occurred: " + error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box className='r' sx={{ p: 3, maxWidth: 500, mx: "auto", color: "white", height: "90vh" }}>
            <Typography
                variant='h2'
                fontSize='30px'
                style={{ borderBottom: "2px solid white", marginBottom: "1%", color: "white", textAlign: "center" }}
            >
                Sign Up
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    margin='normal'
                    label='Firstname'
                    variant='outlined'
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                        classes: {
                            notchedOutline: {
                                borderColor: "white",
                            },
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "white",
                            },
                            "&:hover fieldset": {
                                borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "white",
                            },
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                    }}
                />
                <TextField
                    fullWidth
                    margin='normal'
                    label='Surname'
                    variant='outlined'
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                        classes: {
                            notchedOutline: {
                                borderColor: "white",
                            },
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "white",
                            },
                            "&:hover fieldset": {
                                borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "white",
                            },
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                    }}
                />
                <TextField
                    fullWidth
                    margin='normal'
                    label='Username'
                    variant='outlined'
                    value={username}
                    onChange={(e) => {
                        setUsername(e.target.value);
                        setUsernameError(false); // Clear error on change
                    }}
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                        classes: {
                            notchedOutline: {
                                borderColor: usernameError ? "red" : "white",
                            },
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: usernameError ? "red" : "white",
                            },
                            "&:hover fieldset": {
                                borderColor: usernameError ? "red" : "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: usernameError ? "red" : "white",
                            },
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                    }}
                />
                <TextField
                    fullWidth
                    margin='normal'
                    label='Email'
                    type='email'
                    variant='outlined'
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError(false); // Clear error on change
                    }}
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                        classes: {
                            notchedOutline: {
                                borderColor: emailError ? "red" : "white",
                            },
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: emailError ? "red" : "white",
                            },
                            "&:hover fieldset": {
                                borderColor: emailError ? "red" : "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: emailError ? "red" : "white",
                            },
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                    }}
                />
                <TextField
                    fullWidth
                    margin='normal'
                    label='Password'
                    type='password'
                    variant='outlined'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                        classes: {
                            notchedOutline: {
                                borderColor: "white",
                            },
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "white",
                            },
                            "&:hover fieldset": {
                                borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "white",
                            },
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                    }}
                />

                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    sx={{
                        mt: 2,
                        backgroundColor: "white",
                        color: "black",
                        "&:hover": {
                            backgroundColor: "white",
                        },
                    }}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : "Sign Up"}
                </Button>
                <p style={{ textAlign: "center" }}>
                    Already have an account?
                    <Link to='/login' className='rf-nav-link' style={{ fontSize: "medium" }}>
                        Login here!
                    </Link>
                </p>
            </form>
            {signupStatus && (
                <Alert severity={signupStatus === "Signup successful!" ? "success" : "error"} sx={{ mt: 2 }}>
                    {signupStatus}
                </Alert>
            )}
        </Box>
    );
};

export default SignupForm;
