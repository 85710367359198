import React from "react";
import Content from "../components/content/Content";
import ImageContainer from "../components/content/ImageContainer";

const Home = () => {
    return (
        <div className='rf-home'>
            <ImageContainer />
            <Content />
        </div>
    );
};

export default Home;
