import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
    Box,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useMediaQuery,
    TextField,
    useTheme,
} from "@mui/material";
import { formatDate } from "../utils/DateFormatUtil";
import "../../styling/App.css";
import { useNavigate } from "react-router-dom";
import StatusButton from "../utils/TableStatusCheck";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
const EventAdminUsers = () => {
    const { id } = useParams(); // Extract the event ID from the route parameter
    const [event, setEvent] = useState(null);
    const [users, setUsers] = useState([]);
    const [bookedUserCount, setBookedUser] = useState(0);
    const [pendingUserCount, setPendingUser] = useState(0);
    const [cancelledUserCount, setCancelledUser] = useState(0);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [sortDirection, setSortDirection] = useState("asc");
    const [sortColumn, setSortColumn] = useState("id");
    const [filter, setFilter] = useState("");

    if (isMobile) {
        navigate("/");
    }

    useEffect(() => {
        const fetchEventAndUsers = async () => {
            try {
                const token = localStorage.getItem("token");
                // Fetch event details
                const eventResponse = await axios.get(`https://resurgence-api.xyz/events/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setEvent(eventResponse.data);

                // Fetch susers associated with the event
                const usersResponse = await axios.get(`https://resurgence-api.xyz/bookings/event/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUsers(usersResponse.data);
                updateValues(usersResponse.data);
            } catch (error) {}
        };

        fetchEventAndUsers();
    }, [id]); // Re-fetch data if `id` changes

    const updateValues = async (users) => {
        const confirmedUsers = users.filter((user) => user.status.toLowerCase() === "confirmed");
        const pendingUsers = users.filter((user) => user.status.toLowerCase() === "pending");
        const cancelledUsers = users.filter((user) => user.status.toLowerCase() === "cancelled");
        const rejectedUsers = users.filter((user) => user.status.toLowerCase() === "rejected");

        const confirmedCount = confirmedUsers.length;
        const pendingCount = pendingUsers.length;
        const cancelledCount = cancelledUsers.length + rejectedUsers.length;

        setBookedUser(confirmedCount);
        setPendingUser(pendingCount);
        setCancelledUser(cancelledCount);
    };

    // Sorting function
    const handleSort = (column) => {
        const isAscending = sortColumn === column && sortDirection === "asc";
        setSortDirection(isAscending ? "desc" : "asc");
        setSortColumn(column);
    };

    // Filter users
    const filteredUsers = users.filter((user) =>
        Object.values(user).some((value) => value.toString().toLowerCase().includes(filter.toLowerCase()))
    );

    // Sort users
    const sortedUsers = filteredUsers.sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
        return 0;
    });
    const handleConfirm = async (id) => {
        try {
            const token = localStorage.getItem("token");
            const bookingData = {
                status: "confirmed",
            };
            await axios.put(`https://resurgence-api.xyz/bookings/${id}`, bookingData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const delay = 1000;

            setTimeout(() => {
                window.location.reload();
            }, delay);
        } catch (error) {}
    };

    const handleReject = async (id) => {
        try {
            const token = localStorage.getItem("token");
            const bookingData = {
                status: "rejected",
            };
            await axios.put(`https://resurgence-api.xyz/bookings/${id}`, bookingData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const delay = 1000;

            setTimeout(() => {
                window.location.reload();
            }, delay);
        } catch (error) {}
    };

    return (
        <div>
            <div
                style={{
                    padding: "20px",
                    borderRadius: "8px",
                    color: "white",
                    maxWidth: "600px",
                    margin: "0 auto",
                }}
            >
                <h1>Event Details</h1>
                {event && (
                    <div>
                        <h2>{event.title}</h2>
                        <p> - {event.description}</p>
                        <p> - Date: {formatDate(event.event_date)}</p>
                        {/* Add more event details as needed */}
                    </div>
                )}
            </div>

            <Box sx={{ p: 3, maxWidth: "80%", mx: "auto", color: "white", borderRadius: 1, mt: 4 }}>
                <Typography variant='h4' component='h2' gutterBottom style={{ textAlign: "center" }}>
                    Attending ({bookedUserCount}) | Pending ({pendingUserCount}) | Not Attending ({cancelledUserCount})
                </Typography>
                <TextField
                    label='Filter Search'
                    variant='outlined'
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                        classes: {
                            notchedOutline: {
                                borderColor: "white",
                            },
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "white",
                            },
                            "&:hover fieldset": {
                                borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "white",
                            },
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                        width: "100%",
                    }}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
                <TableContainer component={Paper} sx={{ backgroundColor: "transparent", minHeight: "50vh" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {["id", "username", "firstname", "surname", "email", "status"].map((column) => (
                                    <TableCell
                                        key={column}
                                        sx={{ color: "white", fontSize: "large", cursor: "pointer" }}
                                        onClick={() => handleSort(column)}
                                    >
                                        {column.charAt(0).toUpperCase() + column.slice(1)}
                                        {sortColumn === column &&
                                            (sortDirection === "asc" ? <ArrowUpward /> : <ArrowDownward />)}
                                    </TableCell>
                                ))}
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedUsers.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell sx={{ color: "white" }}>{user.id}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{user.username}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{user.firstname}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{user.surname}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{user.email}</TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        <StatusButton status={user.status} />
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        {user.status !== "confirmed" &&
                                            user.status !== "rejected" &&
                                            user.status !== "cancelled" && (
                                                <>
                                                    <Button
                                                        size='small'
                                                        onClick={() => handleConfirm(user.id)}
                                                        sx={{ color: "white", borderColor: "white" }}
                                                    >
                                                        Confirm
                                                    </Button>
                                                    <Button
                                                        size='small'
                                                        onClick={() => handleReject(user.id)}
                                                        sx={{ color: "white", borderColor: "white" }}
                                                    >
                                                        Reject
                                                    </Button>
                                                </>
                                            )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default EventAdminUsers;
