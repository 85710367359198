import React from "react";
import GalleryGrid from "../components/content/GalleryGrid";

const Gallery = () => {
    return (
        <div className='rf-home'>
            <GalleryGrid />
        </div>
    );
};

export default Gallery;
