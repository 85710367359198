import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";

const PasswordResetForm = () => {
    const { search } = useLocation(); // Get query string from URL
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [userID, setUserID] = useState("");
    const [resetToken, setToken] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(search);
        const token = queryParams.get("token");
        const userid = queryParams.get("userid");

        if (token && userid) {
            setToken(token);
            setUserID(userid);
        } else {
            setError("Invalid token provided. Request a new password reset.");
        }
    }, [search]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        setLoading(true);

        try {
            await axios.post(`https://resurgence-api.xyz/util/reset-password/`, {
                token: resetToken,
                userid: userID,
                newPassword,
            });
            setSuccess("Password reset successfully.");
            navigate("/login"); // Redirect to login page after success
        } catch (error) {
            setError("Error resetting password.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ maxWidth: 400, mx: "auto", mt: 5, p: 3, backgroundColor: "transparent" }}>
            <Typography
                variant='h2'
                fontSize='30px'
                style={{ borderBottom: "2px solid white", marginBottom: "1%", color: "white", textAlign: "center" }}
            >
                Reset Your Password
            </Typography>
            {error && <Alert severity='error'>{error}</Alert>}
            {success && <Alert severity='success'>{success}</Alert>}
            <form onSubmit={handleSubmit}>
                <TextField
                    type='password'
                    label='New Password'
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "white",
                            },
                            "&:hover fieldset": {
                                borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "white",
                            },
                        },
                        "& .MuiInputLabel-root": {
                            color: "white",
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                    }}
                />
                <TextField
                    type='password'
                    label='Confirm Password'
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "white",
                            },
                            "&:hover fieldset": {
                                borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "white",
                            },
                        },
                        "& .MuiInputLabel-root": {
                            color: "white",
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                    }}
                />
                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    sx={{
                        mt: 2,
                        backgroundColor: "white",
                        color: "black",
                        "&:hover": {
                            backgroundColor: "#f0f0f0",
                        },
                    }}
                    disabled={loading}
                >
                    {loading ? "Processing..." : "Reset Password"}
                </Button>
            </form>
        </Box>
    );
};

export default PasswordResetForm;
