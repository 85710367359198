import React from "react";
import Services from "../components/content/Services";

const Service = () => {
    return (
        <div className='rf-home'>
            <Services />
        </div>
    );
};

export default Service;
