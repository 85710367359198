import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../../styling/App.css";
import Logo from "../../resources/logo.jpg";
import HomeIcon from "@mui/icons-material/Home";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import BuildIcon from "@mui/icons-material/Build";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const Navbar = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [username, setUsername] = useState(null);
    const [isAdmin, setAdmin] = useState(false);
    const [isTokenExpired, setTokenExpiry] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();

    useEffect(() => {
        const getUsernameFromStorage = () => {
            const storedUsername = localStorage.getItem("userName");
            setUsername(storedUsername);
        };

        const getTokenExpiry = () => {
            const storedTokenExpiry = localStorage.getItem("token_expiry");

            if (new Date().getTime() > new Date(storedTokenExpiry).getTime()) {
                setTokenExpiry(true);
            }
        };

        const getAdminFromStorage = () => {
            const storedAdmin = localStorage.getItem("isAdmin");
            setAdmin(storedAdmin === "true");
        };

        getAdminFromStorage();
        getUsernameFromStorage();
        getTokenExpiry();

        window.addEventListener("storage", getUsernameFromStorage);

        return () => {
            window.removeEventListener("storage", getUsernameFromStorage);
        };
    }, []);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const signOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("token_expiry");
        localStorage.removeItem("userName");
        localStorage.removeItem("isAdmin");
        localStorage.removeItem("userId");
        setUsername(null);
        setAdmin(false);
        navigate("/login");
    };

    const settings = () => {
        navigate("/settings");
    };

    const drawer = (
        <div onClick={handleDrawerToggle} className='rf-nav-list'>
            <List>
                <ListItem button component={Link} to='/' className='rf-nav-list-item'>
                    <ListItemIcon>
                        <HomeIcon style={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary='Home' />
                </ListItem>
                <ListItem button component={Link} to='/gallery' className='rf-nav-list-item'>
                    <ListItemIcon>
                        <PhotoLibraryIcon style={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary='Gallery' />
                </ListItem>
                <ListItem button component={Link} to='/services' className='rf-nav-list-item'>
                    <ListItemIcon>
                        <BuildIcon style={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary='Services' />
                </ListItem>
                <ListItem button component={Link} to='/contact' className='rf-nav-list-item'>
                    <ListItemIcon>
                        <ContactMailIcon style={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary='Contact' />
                </ListItem>
                <ListItem button component={Link} to='/booking' className='rf-nav-list-item'>
                    <ListItemIcon>
                        <CalendarMonthIcon style={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary='Bookings' />
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className='rf-navbar'>
            <AppBar position='static'>
                <Toolbar className='rf-nav-content'>
                    <Link to='/' className='rf-logo-link'>
                        <img src={Logo} alt='Logo' className='rf-logo' />
                    </Link>
                    {isMobile ? (
                        username ? (
                            <div className='rf-nav' style={{ display: "flex", alignItems: "center" }}>
                                <Typography variant='h3' className='rf-home-link' style={{ textAlign: "left" }}>
                                    {username}
                                </Typography>
                                <IconButton
                                    className='rf-icon-buttons'
                                    color='inherit'
                                    aria-label='settings'
                                    onClick={settings}
                                    style={{
                                        backgroundColor: "#111020",
                                        color: "white",
                                        borderRadius: "50%",
                                    }}
                                >
                                    <SettingsIcon
                                        style={{
                                            backgroundColor: "#111020",
                                            color: "white",
                                            borderRadius: "50%",
                                            padding: "5px",
                                        }}
                                    />
                                </IconButton>
                                <IconButton
                                    className='rf-icon-buttons'
                                    color='inherit'
                                    aria-label='sign-out'
                                    onClick={signOut}
                                    style={{
                                        backgroundColor: "#111020",
                                        color: "white",
                                        borderRadius: "50%",
                                        padding: "5px",
                                    }}
                                >
                                    <LogoutIcon
                                        style={{
                                            backgroundColor: "#111020",
                                            color: "white",
                                            borderRadius: "50%",
                                            padding: "5px",
                                        }}
                                    />
                                </IconButton>
                            </div>
                        ) : (
                            <div className='rf-nav' style={{ display: "flex", alignItems: "center" }}>
                                <Typography variant='h3' className='rf-home-link' style={{ textAlign: "left" }}>
                                    Resurgence Fitness
                                </Typography>
                            </div>
                        )
                    ) : (
                        <div className='rf-nav-links' style={{ display: "flex", alignItems: "center" }}>
                            <Link to='/' className='rf-nav-link'>
                                Home
                            </Link>
                            <Link to='/gallery' className='rf-nav-link'>
                                Gallery
                            </Link>
                            <Link to='/services' className='rf-nav-link'>
                                Services
                            </Link>
                            <Link to='/contact' className='rf-nav-link'>
                                Contact
                            </Link>
                            <Link to='/booking' className='rf-nav-link-special'>
                                Book Now!
                            </Link>
                        </div>
                    )}

                    {username && !isTokenExpired && !isMobile && (
                        <div
                            className='rf-nav-links'
                            style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
                        >
                            {isAdmin && (
                                <Link to='/admin' className='rf-nav-link'>
                                    Admin
                                </Link>
                            )}
                            <Link to='/booking' className='rf-nav-link'>
                                My Bookings
                            </Link>
                            <div className='rf-nav-link' style={{ display: "flex", alignItems: "center" }}>
                                <p>{username}</p>
                                <IconButton
                                    className='rf-icon-buttons'
                                    color='inherit'
                                    aria-label='settings'
                                    onClick={settings}
                                    style={{
                                        backgroundColor: "#111020",
                                        color: "white",
                                        borderRadius: "50%",
                                    }}
                                >
                                    <SettingsIcon
                                        style={{
                                            backgroundColor: "#111020",
                                            color: "white",
                                            borderRadius: "50%",
                                            padding: "5px",
                                        }}
                                    />
                                </IconButton>
                                <IconButton
                                    className='rf-icon-buttons'
                                    color='inherit'
                                    aria-label='sign-out'
                                    onClick={signOut}
                                    style={{
                                        backgroundColor: "#111020",
                                        color: "white",
                                        borderRadius: "50%",
                                        padding: "5px",
                                    }}
                                >
                                    <LogoutIcon
                                        style={{
                                            backgroundColor: "#111020",
                                            color: "white",
                                            borderRadius: "50%",
                                            padding: "5px",
                                        }}
                                    />
                                </IconButton>
                            </div>
                        </div>
                    )}

                    {!username && !isMobile && (
                        <div
                            className='rf-nav-links'
                            style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
                        >
                            <Link to='/login' className='rf-nav-link'>
                                Login
                            </Link>
                        </div>
                    )}

                    {!username && isMobile && (
                        <div
                            className='rf-nav-links'
                            style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
                        >
                            <Link to='/login' className='rf-nav-link' style={{ fontSize: "medium" }}>
                                Login
                            </Link>
                        </div>
                    )}

                    {isMobile && (
                        <IconButton
                            edge='end'
                            color='inherit'
                            aria-label='menu'
                            onClick={handleDrawerToggle}
                            style={{ margin: "0" }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                </Toolbar>

                <Drawer
                    anchor='left'
                    open={drawerOpen}
                    onClose={handleDrawerToggle}
                    PaperProps={{
                        style: {
                            marginTop: "8vh",
                            width: "80%",
                            backgroundColor: "#111020",
                            color: "white",
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </AppBar>
        </div>
    );
};

export default Navbar;
