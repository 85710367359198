import "./styling/App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

//Pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Service from "./pages/Service";
import Booking from "./pages/Booking";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import SettingsPage from "./pages/Settings";
import Reset from "./pages/ResetPassword";
import Forgot from "./pages/ForgotPassword";
import AdminEvent from "./pages/AdminEvent";
import AdminUser from "./pages/AdminUser";
import AdminN from "./pages/AdminNav";
import AdminEU from "./pages/AdminEventUsers";

//Fixed components
import Navbar from "./components/content/Navbar";
import Footer from "./components/content/Footer";

const App = () => {
    return (
        <Router>
            <div>
                <Navbar />
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/gallery' element={<Gallery />} />
                    <Route path='/services' element={<Service />} />
                    <Route path='/booking' element={<Booking />} />
                    <Route path='/signup' element={<SignUp />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/forgot-password' element={<Forgot />} />
                    <Route path='/reset-password' element={<Reset />} />
                    <Route path='/settings' element={<SettingsPage />} />
                    <Route path='/admin/events' element={<AdminEvent />} />
                    <Route path='/admin/users' element={<AdminUser />} />
                    <Route path='/admin' element={<AdminN />} />
                    <Route path='/admin/events/:id' element={<AdminEU />} />
                    <Route path='*' element={<Navigate to='/' />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
