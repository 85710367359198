import React from "react";
import TokenCheck from "../components/utils/TokenCheck";
import AdminCheck from "../components/utils/AdminCheck";
import EventAdmin from "../components/admin/EventAdmin";

const AdminEvent = () => {
    return (
        <div className='rf-home'>
            <TokenCheck>
                <AdminCheck>
                    <EventAdmin />
                </AdminCheck>
            </TokenCheck>
        </div>
    );
};

export default AdminEvent;
