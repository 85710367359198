import React from "react";
import LoginForm from "../components/api/LoginForm";

const Login = () => {
    return (
        <div className='rf-home'>
            <LoginForm />
        </div>
    );
};

export default Login;
