import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
    CircularProgress,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

// Custom styled DatePicker
const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "white",
        },
        "&:hover fieldset": {
            borderColor: "white",
        },
        "&.Mui-focused fieldset": {
            borderColor: "white",
        },
    },
    "& .MuiInputBase-input": {
        color: "white",
    },
    "& .MuiInputLabel-root": {
        color: "white",
    },
    "& .MuiIconButton-root": {
        color: "white", // Ensure the calendar icon color is white
    },
}));

const AdminEventForm = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [eventDate, setEventDate] = useState(null); // Initialize as null or dayjs object
    const [location, setLocation] = useState("");
    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [events, setEvents] = useState([]);
    const [editingEventId, setEditingEventId] = useState(null); // State to track editing event
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    if (isMobile) {
        navigate("/");
    }

    const fetchEvents = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios.get("https://resurgence-api.xyz/events", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setEvents(response.data);
        } catch (error) {}
    };

    const handleView = (eventId) => {
        // Navigate to the route that shows details about the event
        navigate(`/admin/events/${eventId}`);
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setStatusMessage("");

        try {
            const token = localStorage.getItem("token");

            // Prepare the event data
            const eventData = {
                title,
                description,
                event_date: eventDate ? eventDate.format("YYYY-MM-DD") : "", // Convert to desired format if needed
                location,
            };

            if (editingEventId) {
                // If editingEventId is present, update the event
                await axios.put(`https://resurgence-api.xyz/events/${editingEventId}`, eventData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setStatusMessage("Event updated successfully!");
            } else {
                // Otherwise, create a new event
                await axios.post("https://resurgence-api.xyz/events", eventData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setStatusMessage("Event created successfully!");
            }

            setTitle("");
            setDescription("");
            setEventDate(null);
            setLocation("");
            setEditingEventId(null); // Reset editing event ID
            fetchEvents(); // Refresh events list
        } catch (error) {
            setStatusMessage("Error saving event. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem("token");
            await axios.delete(`https://resurgence-api.xyz/events/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setStatusMessage("Event deleted successfully!");
            fetchEvents(); // Refresh events list
        } catch (error) {
            setStatusMessage("Error deleting event. Please try again.");
        }
    };

    const handleEdit = (event) => {
        setTitle(event.title);
        setDescription(event.description);
        setEventDate(dayjs(event.event_date)); // Ensure event_date is converted to dayjs object
        setLocation(event.location);
        setEditingEventId(event.id); // Set editing event ID
    };

    return (
        <div>
            <Typography
                variant='h2'
                className='rf-title-content'
                fontSize='x-large'
                style={{ borderBottom: "2px solid white", marginBottom: "1%" }}
            >
                Create, Update or Delete Events
            </Typography>
            <Box sx={{ p: 3, maxWidth: "40%", mx: "auto", color: "white", borderRadius: 1 }}>
                <Typography variant='h4' component='h1' gutterBottom>
                    {editingEventId ? "Edit Event" : "Create Event"}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            margin='normal'
                            label='Event Title'
                            variant='outlined'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                            InputProps={{
                                style: { color: "white" },
                            }}
                            InputLabelProps={{
                                style: { color: "white" },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "white",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "white",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "white",
                                    },
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            margin='normal'
                            label='Description'
                            variant='outlined'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            multiline
                            rows={4}
                            InputProps={{
                                style: { color: "white" },
                            }}
                            InputLabelProps={{
                                style: { color: "white" },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "white",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "white",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "white",
                                    },
                                },
                            }}
                        />
                        <StyledDatePicker
                            className='picker'
                            label='Event Date'
                            value={eventDate}
                            onChange={(newValue) => setEventDate(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    margin='normal'
                                    InputProps={{
                                        style: { color: "white" },
                                        ...params.InputProps,
                                    }}
                                    InputLabelProps={{
                                        style: { color: "white" },
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                borderColor: "white",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "white",
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "white",
                                            },
                                        },
                                    }}
                                />
                            )}
                            required
                        />
                        <TextField
                            fullWidth
                            margin='normal'
                            label='Location'
                            variant='outlined'
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            InputProps={{
                                style: { color: "white" },
                            }}
                            InputLabelProps={{
                                style: { color: "white" },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "white",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "white",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "white",
                                    },
                                },
                            }}
                        />
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            fullWidth
                            sx={{
                                mt: 2,
                                backgroundColor: "white",
                                color: "black",
                                "&:hover": {
                                    backgroundColor: "white",
                                },
                            }}
                            disabled={loading}
                        >
                            {loading ? (
                                <CircularProgress size={24} />
                            ) : editingEventId ? (
                                "Update Event"
                            ) : (
                                "Create Event"
                            )}
                        </Button>
                    </form>
                    {statusMessage && (
                        <Alert severity={statusMessage.startsWith("Error") ? "error" : "success"} sx={{ mt: 2 }}>
                            {statusMessage}
                        </Alert>
                    )}
                </LocalizationProvider>
            </Box>
            <Box sx={{ p: 3, maxWidth: "80%", mx: "auto", color: "white", borderRadius: 1, mt: 4 }}>
                <Typography variant='h4' component='h2' gutterBottom>
                    Event List
                </Typography>
                <TableContainer component={Paper} sx={{ backgroundColor: "transparent", minHeight: "50vh" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Title</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Description</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Date</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Location</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {events.map((event) => (
                                <TableRow key={event.id}>
                                    <TableCell sx={{ color: "white" }}>{event.title}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{event.description}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{event.event_date}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{event.location}</TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        <Button
                                            size='small'
                                            onClick={() => handleView(event.id)}
                                            sx={{ color: "white", borderColor: "white" }}
                                        >
                                            View
                                        </Button>
                                        <Button
                                            size='small'
                                            onClick={() => handleEdit(event)}
                                            sx={{ color: "white", borderColor: "white" }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            size='small'
                                            onClick={() => handleDelete(event.id)}
                                            sx={{ color: "white", borderColor: "white" }}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default AdminEventForm;
