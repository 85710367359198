import React from "react";
import "../../styling/App.css"; // Assuming you will use this CSS file
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link, Navigate } from "react-router-dom";

const Services = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    if (isMobile) {
        return <Navigate to='/' />;
    }

    return (
        <div className='rf-services'>
            <Typography
                variant='h2'
                className='rf-title-content'
                fontSize='x-large'
                style={{ borderBottom: "2px solid white", marginBottom: "1%" }}
            >
                Admin
            </Typography>
            <div className='rf-nav-links' style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
                <Link to='/admin/events' className='rf-nav-link'>
                    Events
                </Link>
                <Link to='/admin/users' className='rf-nav-link'>
                    Users
                </Link>
            </div>
        </div>
    );
};

export default Services;
