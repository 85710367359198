import React from "react";
import ContactUs from "../components/content/ContactUs";

const Contact = () => {
    return (
        <div className='rf-home'>
            <ContactUs />
        </div>
    );
};

export default Contact;
