import React from "react";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "../../styling/App.css";
import Logo from "../../resources/logo.jpg";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const handleLinkClick = () => {
        // Handle any additional actions if needed before opening the link
    };
    return (
        <div className='rf-footer'>
            <Toolbar className='rf-footer-content'>
                <div className='rf-footer-left'>
                    <div className='rf-footer-left-item'>
                        <a
                            href='https://www.facebook.com/profile.php?id=61560570819900&locale=en_GB'
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={handleLinkClick}
                        >
                            <FacebookIcon fontSize='large' style={{ color: "white" }} />
                        </a>
                    </div>
                </div>
                <div className='rf-footer-right'>
                    <Typography variant='body2' className='rf-copyright'>
                        &copy; {currentYear} Resurgence Fitness; Designed by&nbsp;
                        <a
                            href='https://www.linkedin.com/in/liam-lenton-2728a0178/'
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={handleLinkClick}
                        >
                            Liam Lenton
                        </a>
                    </Typography>
                </div>
            </Toolbar>
        </div>
    );
};

export default Footer;
