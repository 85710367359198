import React, { useState } from "react";
import axios from "axios";
import { Box, Button, TextField, Typography, Alert, CircularProgress } from "@mui/material";
import "../../styling/App.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const LoginForm = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginStatus, setLoginStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username || !password) {
            setLoginStatus("Please fill out all fields.");
            return;
        }

        setLoading(true);

        try {
            const loginData = {
                username,
                password,
            };
            // Send login request to the backend
            const response = await axios.post(`https://resurgence-api.xyz/auth/login`, loginData);

            // Handle successful login
            setLoginStatus("Login successful!");

            // Store the data
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("token_expiry", response.data.token_expiryDate);
            localStorage.setItem("userId", response.data.userId);
            localStorage.setItem("userName", response.data.userName);

            var isAdmin = false;
            if (response.data.isAdmin == 1) {
                isAdmin = true;
            }
            localStorage.setItem("isAdmin", isAdmin);

            navigate("/");
            window.location.reload(); // Refresh the page
        } catch (error) {
            setLoginStatus("Error logging in. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box className='r' sx={{ p: 3, maxWidth: 500, mx: "auto", color: "white", height: "90vh" }}>
            <Typography
                variant='h2'
                fontSize='30px'
                style={{ borderBottom: "2px solid white", marginBottom: "1%", color: "white", textAlign: "center" }}
            >
                Login
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    margin='normal'
                    label='Username'
                    variant='outlined'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                        classes: {
                            notchedOutline: {
                                borderColor: "white",
                            },
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "white",
                            },
                            "&:hover fieldset": {
                                borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "white",
                            },
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                    }}
                />
                <TextField
                    fullWidth
                    margin='normal'
                    label='Password'
                    type='password'
                    variant='outlined'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputLabelProps={{
                        style: { color: "white" },
                    }}
                    InputProps={{
                        style: { color: "white" },
                        classes: {
                            notchedOutline: {
                                borderColor: "white",
                            },
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "white",
                            },
                            "&:hover fieldset": {
                                borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "white",
                            },
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                    }}
                />
                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    sx={{
                        mt: 2,
                        backgroundColor: "white",
                        color: "black",
                        "&:hover": {
                            backgroundColor: "white",
                        },
                    }}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : "Login"}
                </Button>
                <p style={{ textAlign: "center" }}>
                    Don't have an account yet?
                    <Link to='/signup' className='rf-nav-link' style={{ fontSize: "medium" }}>
                        Sign Up!
                    </Link>
                </p>
                <p style={{ textAlign: "center" }}>
                    Forgot password?
                    <Link to='/forgot-password' className='rf-nav-link' style={{ fontSize: "medium" }}>
                        Click here!
                    </Link>
                </p>
            </form>
            {loginStatus && (
                <Alert severity={loginStatus === "Login successful!" ? "success" : "error"} sx={{ mt: 2 }}>
                    {loginStatus}
                </Alert>
            )}
        </Box>
    );
};

export default LoginForm;
