import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import axios from "axios";
import "../../styling/App.css";
import { useNavigate } from "react-router-dom";
const UserAdmin = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [eventDate, setEventDate] = useState(null); // Initialize as null or dayjs object
    const [location, setLocation] = useState("");
    const [users, setUsers] = useState([]);
    const [editingEventId, setEditingEventId] = useState(null); // State to track editing event
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    if (isMobile) {
        navigate("/");
    }

    const fetchUsers = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios.get("https://resurgence-api.xyz/users", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUsers(response.data);
        } catch (error) {}
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleDelete = async (id) => {
        try {
            const token = localStorage.getItem("token");
            await axios.delete(`https://resurgence-api.xyz/users/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchUsers(); // Refresh events list
        } catch (error) {}
    };

    return (
        <div style={{ minHeight: "90vh" }}>
            <Typography
                variant='h2'
                className='rf-title-content'
                fontSize='x-large'
                style={{ borderBottom: "2px solid white", marginBottom: "1%" }}
            >
                Remove users
            </Typography>
            <Box sx={{ p: 3, maxWidth: "80%", mx: "auto", color: "white", borderRadius: 1, mt: 4 }}>
                <Typography variant='h4' component='h2' gutterBottom>
                    User List
                </Typography>
                <TableContainer component={Paper} sx={{ backgroundColor: "transparent", minHeight: "50vh" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Firstname</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Surname</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Username</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Email</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Created At</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Updated At</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Admin</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "large" }}>Action(s)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell sx={{ color: "white" }}>{user.firstname}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{user.surname}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{user.username}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{user.email}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{user.created_at}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{user.updated_at}</TableCell>
                                    <TableCell sx={{ color: "white" }}>{user.isAdmin ? "Yes" : "No"}</TableCell>
                                    <TableCell sx={{ color: "white" }}>
                                        <Button
                                            size='small'
                                            onClick={() => handleDelete(user.id)}
                                            sx={{ color: "white", borderColor: "white" }}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default UserAdmin;
