import React from "react";
import Button from "@mui/material/Button";

const getStatusStyles = (status) => {
    switch (status) {
        case "pending":
            return { backgroundColor: "orange", color: "white" };
        case "confirmed":
            return { backgroundColor: "green", color: "white" };
        case "cancelled":
            return { backgroundColor: "red", color: "white" };
        default:
            return { backgroundColor: "grey", color: "white" }; // Default styling if status is unknown
    }
};

const StatusButton = ({ status }) => {
    const styles = getStatusStyles(status);
    return (
        <Button
            variant='contained'
            sx={{
                ...styles,
                cursor: "default", // Ensure cursor appears as default
                "&:hover": {
                    backgroundColor: styles.backgroundColor, // Maintain the same color on hover
                },
            }}
        >
            {status}
        </Button>
    );
};

export default StatusButton;
