import React from "react";
import "../../styling/App.css"; // Assuming you will use this CSS file
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";

const ContactUs = () => {
    return (
        <div class='contact-container'>
            <Typography
                variant='h2'
                class='rf-title-content'
                fontSize='x-large'
                style={{ borderBottom: "2px solid white", marginBottom: "1%" }}
            >
                Get in touch and
                <br />
                RESURGE your fitness journey today!
            </Typography>
            <div class='contact-content'>
                <div class='contact-info'>
                    <Card className='rf-contact-card'>
                        <LocationOnIcon
                            fontSize='large'
                            className='rf-content-icon'
                            style={{ backgroundColor: "#111020", color: "white", borderRadius: "50%", padding: "5px" }}
                        />
                        <h3>Address</h3>
                        <p>
                            The Old Bowling Green
                            <br />
                            Mexborough Athletic Ground
                            <br />
                            New Oxford Road
                            <br />
                            Mexborough
                            <br />
                            S64 0JL
                        </p>
                    </Card>
                    <Card className='rf-contact-card'>
                        <CallIcon
                            fontSize='large'
                            className='rf-content-icon'
                            style={{ backgroundColor: "#111020", color: "white", borderRadius: "50%", padding: "5px" }}
                        />
                        <h3>Phone</h3>
                        <p>
                            <a href='tel:+4407527523243' class='contact-link'>
                                (+44) 07527 523243
                            </a>
                        </p>
                    </Card>
                    <Card className='rf-contact-card'>
                        <AlternateEmailIcon
                            fontSize='large'
                            className='rf-content-icon'
                            style={{ backgroundColor: "#111020", color: "white", borderRadius: "50%", padding: "5px" }}
                        />
                        <h3>Email</h3>
                        <p>
                            <a href='mailto:resurgence.fitness24@gmail.com' class='contact-link'>
                                resurgence.fitness24@gmail.com
                            </a>
                        </p>
                    </Card>
                </div>
                <Card className='rf-contact-card'>
                    <iframe
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1186.7968747602993!2d-1.283010257655955!3d53.49358820977436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487973a237610e87%3A0xb8ec170f257c76a4!2sMexborough%20Athletic%20Sports%20Club!5e0!3m2!1sen!2suk!4v1721395439310!5m2!1sen!2suk'
                        width='100%'
                        height='300'
                        style={{ border: 0 }}
                        allowFullScreen
                        loading='lazy'
                        referrerPolicy='no-referrer-when-downgrade'
                        title='Google Maps Embed'
                    ></iframe>
                </Card>
            </div>
        </div>
    );
};

//https://www.google.co.uk/maps/place/Mexborough+Athletic+Sports+Club/@53.4937647,-1.2844842,18z/data=!4m14!1m7!3m6!1s0x487973a237610e87:0xb8ec170f257c76a4!2sMexborough+Athletic+Sports+Club!8m2!3d53.4937281!4d-1.2831613!16s%2Fg%2F1tff73w_!3m5!1s0x487973a237610e87:0xb8ec170f257c76a4!8m2!3d53.4937281!4d-1.2831613!16s%2Fg%2F1tff73w_?entry=ttu

export default ContactUs;
