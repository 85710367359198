import React from "react";
import "../../styling/App.css"; // Assuming you will use this CSS file
import { Typography } from "@mui/material";
import Info from "../../resources/information_leaflet.jpg";
import Trn from "../../resources/personal_training.jpg";
import Nut from "../../resources/nutrition_guide.jpg";
import Card from "@mui/material/Card";
import GroupsIcon from "@mui/icons-material/Groups";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SportsIcon from "@mui/icons-material/Sports";

const Services = () => {
    return (
        <div className='rf-services'>
            <Typography
                variant='h2'
                class='rf-title-content'
                fontSize='x-large'
                style={{ borderBottom: "2px solid white", marginBottom: "1%" }}
            >
                What do you want to do?
            </Typography>
            <div className='rf-services-content'>
                <Card className='rf-image-services-card'>
                    <Typography
                        variant='body1'
                        className='rf-overlay-text'
                        fontSize='x-large'
                        style={{ textAlign: "center", color: "black" }}
                    >
                        <GroupsIcon
                            fontSize='large'
                            className='rf-content-icon'
                            style={{ backgroundColor: "#111020", color: "white", borderRadius: "50%", padding: "5px" }}
                        />
                        Outdoor bootcamps
                    </Typography>
                    <Typography variant='body1' className='rf-services-info-text' style={{ color: "black" }}>
                        <h2>Price(s):</h2>
                        - Per Session: £7
                        <br />- Block of sessions: POD
                    </Typography>
                    <Typography variant='body1' className='rf-services-info-text' style={{ color: "black" }}>
                        <h2>Age(s):</h2>- 16+
                    </Typography>
                    <Typography variant='body1' className='rf-services-info-text' style={{ color: "black" }}>
                        <h2>Time(s):</h2>
                        - Every Saturday
                        <br />- 1st Session 07:30 - 08:15
                        <br />- 2nd Session 08:30 - 09:15
                    </Typography>
                    <img src={Info} alt='Outdoor Bootcamps' className='rf-service-image' />
                </Card>
                <Card className='rf-image-services-card'>
                    <Typography
                        variant='body1'
                        className='rf-overlay-text'
                        fontSize='x-large'
                        style={{ textAlign: "center", color: "black" }}
                    >
                        <SportsIcon
                            fontSize='large'
                            className='rf-content-icon'
                            style={{ backgroundColor: "#111020", color: "white", borderRadius: "50%", padding: "5px" }}
                        />
                        Personal Training
                    </Typography>
                    <Typography variant='body1' className='rf-services-info-text' style={{ color: "black" }}>
                        <h2>Price(s):</h2>
                        - POD
                        <br />- Get in touch for a quote!
                    </Typography>
                    <Typography variant='body1' className='rf-services-info-text' style={{ color: "black" }}>
                        <h2>Age(s):</h2>- 16+
                    </Typography>
                    <Typography variant='body1' className='rf-services-info-text' style={{ color: "black" }}>
                        <h2>Time(s):</h2>
                        - We aim to be flexible as possible
                        <br />- So it's whatever works for you!
                        <br />- Get in touch now and start your journey!
                    </Typography>
                    <img src={Trn} alt='Personal Training' className='rf-service-image' />
                </Card>
                <Card className='rf-image-services-card'>
                    <Typography
                        variant='body1'
                        className='rf-overlay-text'
                        fontSize='x-large'
                        style={{ textAlign: "center", color: "black" }}
                    >
                        <RestaurantIcon
                            fontSize='large'
                            className='rf-content-icon'
                            style={{ backgroundColor: "#111020", color: "white", borderRadius: "50%", padding: "5px" }}
                        />
                        Nutrional Guidance
                    </Typography>
                    <Typography variant='body1' className='rf-services-info-text' style={{ color: "black" }}>
                        <h2>Price(s):</h2>
                        - POD
                        <br />- Get in touch for a quote!
                    </Typography>
                    <Typography variant='body1' className='rf-services-info-text' style={{ color: "black" }}>
                        <h2>Age(s):</h2>- 16+
                    </Typography>
                    <Typography variant='body1' className='rf-services-info-text' style={{ color: "black" }}>
                        <h2>Time(s):</h2>
                        - We aim to be flexible as possible
                        <br />- So it's whatever works for you!
                        <br />- Get in touch now and start your journey!
                    </Typography>
                    <img src={Nut} alt='Nutritional Guidance' className='rf-service-image' />
                </Card>
            </div>
            <Typography
                variant='h2'
                class='rf-title-content'
                fontSize='x-large'
                style={{ borderBottom: "2px solid white", marginBottom: "1%" }}
            >
                Any other questions or services required just get in touch
            </Typography>
        </div>
    );
};

export default Services;
