import React from "react";
import SignupForm from "../components/api/SignUpForm";

const SignUp = () => {
    return (
        <div className='rf-home'>
            <SignupForm />
        </div>
    );
};

export default SignUp;
