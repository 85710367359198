import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";

const UserSettingsForm = () => {
    const [firstname, setFirstname] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);

    useEffect(() => {
        // Fetch user details when the component mounts
        const token = localStorage.getItem("token");
        const userid = localStorage.getItem("userId");
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`https://resurgence-api.xyz/users/${userid}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }); // Adjust URL as needed
                const userData = response.data;
                setFirstname(userData.firstname);
                setSurname(userData.surname);
                setEmail(userData.email);
            } catch (err) {
                setError("Error fetching user details.");
            }
        };

        fetchUserDetails();
    }, []);

    const handleSubmit = async (e) => {
        const token = localStorage.getItem("token");
        const userid = localStorage.getItem("userId");
        e.preventDefault();
        setLoading(true);
        try {
            await axios.put(
                `https://resurgence-api.xyz/users/${userid}`,
                {
                    firstname,
                    surname,
                    email,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setSuccess("User details updated successfully.");
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    // Check if the email already exists
                    if (error.response.data.error.includes("Email")) {
                        setEmailError(true);
                    }
                    setError(error.response.data.error);
                } else {
                    setError(error.response.data.error);
                }
            } else if (error.request) {
                setError("No response from the server. Please try again later.");
            } else {
                setError("An error occurred: " + error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                maxWidth: 600,
                margin: "auto", // Center horizontally by setting margin to auto
                backgroundColor: "transparent",
                minHeight: "90vh",
                display: "flex", // Use flexbox for centering
                alignItems: "center", // Center vertically
                justifyContent: "center", // Center horizontally
                flexDirection: "column", // Align items in a column
                padding: 2, // Optional padding for some spacing
            }}
        >
            <Typography
                variant='h2'
                fontSize='30px'
                style={{ borderBottom: "2px solid white", marginBottom: "1%", color: "white", textAlign: "center" }}
            >
                Update Your Details
            </Typography>
            {error && <Alert severity='error'>{error}</Alert>}
            {success && <Alert severity='success'>{success}</Alert>}
            <form onSubmit={handleSubmit}>
                <TextField
                    label='First Name'
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "white",
                            },
                            "&:hover fieldset": {
                                borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "white",
                            },
                        },
                        "& .MuiInputLabel-root": {
                            color: "white",
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                    }}
                />
                <TextField
                    label='Surname'
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "white",
                            },
                            "&:hover fieldset": {
                                borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "white",
                            },
                        },
                        "& .MuiInputLabel-root": {
                            color: "white",
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                    }}
                />
                <TextField
                    label='Email'
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    type='email' // HTML5 email type for built-in validation
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "white",
                            },
                            "&:hover fieldset": {
                                borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "white",
                            },
                        },
                        "& .MuiInputLabel-root": {
                            color: "white",
                        },
                        "& .MuiInputBase-input": {
                            color: "white",
                        },
                    }}
                    required // Make this field required
                />
                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    sx={{
                        mt: 2,
                        backgroundColor: "white",
                        color: "black",
                        "&:hover": {
                            backgroundColor: "#f0f0f0",
                        },
                    }}
                    disabled={loading}
                >
                    {loading ? "Updating..." : "Update Details"}
                </Button>
            </form>
        </Box>
    );
};

export default UserSettingsForm;
