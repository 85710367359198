import React, { useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import { Typography } from "@mui/material";
import heic2any from "heic2any";
import "../../styling/App.css";
import videoThumbnail from "../../resources/placeholder-video.png"; // Adjust path as needed

const importAll = (r) => {
    let mediaFiles = {};
    r.keys().map(async (item) => {
        const filePath = item.replace("./", "");
        const file = r(item);

        if (filePath.endsWith(".heic")) {
            // Handle HEIC images
            try {
                const blob = await fetch(file).then((res) => res.blob());
                const convertedBlob = await heic2any({ blob, toType: "image/jpeg" });
                mediaFiles[filePath] = URL.createObjectURL(convertedBlob);
            } catch (error) {
                console.error("Error converting HEIC file:", error);
            }
        } else {
            mediaFiles[filePath] = file;
        }
    });
    return mediaFiles;
};

// Import all media from the resources folder
const mediaFiles = importAll(require.context("../../resources/gallery", false, /\.(png|jpe?g|svg|heic|mp4)$/));

const GalleryGrid = () => {
    const [open, setOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);

    // Use media query to determine if the screen width is less than 600px
    const isMobile = useMediaQuery("(max-width:600px)");

    const handleClickOpen = (media) => {
        setSelectedMedia(media);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedMedia(null);
    };

    return (
        <div className='rf-gallery'>
            <Typography
                variant='h2'
                class='rf-title-content'
                fontSize='x-large'
                style={{ borderBottom: "2px solid white", marginBottom: "1%" }}
            >
                Let's see what you're missing out on...
            </Typography>
            <ImageList variant='masonry' cols={isMobile ? 2 : 4} gap={8}>
                {Object.keys(mediaFiles).map((file, index) => {
                    const fileType = file.split(".").pop();
                    const isVideo = fileType === "mp4";
                    return (
                        <ImageListItem key={index} onClick={() => handleClickOpen(mediaFiles[file])}>
                            {isVideo ? (
                                <img
                                    src={videoThumbnail} // Use the imported thumbnail for video previews
                                    alt={`Video Thumbnail ${index}`}
                                    loading='lazy'
                                    style={{ width: "100%", height: "auto", cursor: "pointer" }}
                                />
                            ) : (
                                <img
                                    src={mediaFiles[file]}
                                    alt={`Gallery Image ${index}`}
                                    loading='lazy'
                                    style={{ width: "100%", height: "auto", cursor: "pointer" }}
                                />
                            )}
                        </ImageListItem>
                    );
                })}
            </ImageList>
            <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
                <DialogTitle>
                    <IconButton
                        edge='end'
                        color='inherit'
                        onClick={handleClose}
                        aria-label='close'
                        style={{ position: "absolute", right: 8, top: 0 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    {selectedMedia && (
                        <div className='dialog-media-container'>
                            {selectedMedia.endsWith(".mp4") ? (
                                <video
                                    src={selectedMedia}
                                    alt='Selected'
                                    style={{ width: "100%", height: "100%" }}
                                    controls
                                />
                            ) : (
                                <img src={selectedMedia} alt='Selected' style={{ width: "100%", height: "100%" }} />
                            )}
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default GalleryGrid;
