import React from "react";
import "../../styling/App.css";
import Card from "@mui/material/Card";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import GroupsIcon from "@mui/icons-material/Groups";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SportsIcon from "@mui/icons-material/Sports";
import PM from "../../resources/paul_marshall_portrait.jpg";
import { Typography } from "@mui/material";

const Content = () => {
    return (
        <div className='rf-content'>
            <Typography
                variant='p'
                className='rf-title-content'
                fontSize='x-large'
                style={{ borderBottom: "2px solid white", textAlign: "left" }}
            >
                What we strive to do -
            </Typography>
            <div className='rf-content-cards'>
                <Card className='rf-content-card-image'>
                    <img src={PM} alt='Paul Marshall Trainer' className='rf-content-image' />
                    <Typography variant='body1' className='rf-overlay-text' style={{ color: "white" }}>
                        Paul Marshall - Qualified Personal Trainer
                    </Typography>
                </Card>
                <Card className='rf-content-card'>
                    <div className='rf-inline-header'>
                        <FitnessCenterIcon
                            fontSize='large'
                            className='rf-content-icon'
                            style={{ backgroundColor: "#111020", color: "white", borderRadius: "50%", padding: "5px" }}
                        />
                        <h1>Reignite Your Fitness Journey</h1>
                    </div>
                    <div className='rf-inline-header'>
                        <GroupsIcon
                            fontSize='large'
                            className='rf-content-icon'
                            style={{ backgroundColor: "#111020", color: "white", borderRadius: "50%", padding: "5px" }}
                        />
                        <h1>Organise Outdoor Bootcamps Every Weekend</h1>
                    </div>
                    <div className='rf-inline-header'>
                        <SportsIcon
                            fontSize='large'
                            className='rf-content-icon'
                            style={{ backgroundColor: "#111020", color: "white", borderRadius: "50%", padding: "5px" }}
                        />
                        <h1>Personalized 1-2-1 Training</h1>
                    </div>
                    <div className='rf-inline-header'>
                        <RestaurantIcon
                            fontSize='large'
                            className='rf-content-icon'
                            style={{ backgroundColor: "#111020", color: "white", borderRadius: "50%", padding: "5px" }}
                        />
                        <h1>Nutritional Guidance Included With Fitness Plans</h1>
                    </div>
                    <div className='rf-inline-header'>
                        <FlashOnIcon
                            fontSize='large'
                            className='rf-content-icon'
                            style={{ backgroundColor: "#111020", color: "white", borderRadius: "50%", padding: "5px" }}
                        />
                        <h1>Limited Spots Available – Hurry, Act Now!</h1>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Content;
