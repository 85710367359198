// TokenCheck.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TokenCheck = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkToken = () => {
            // Get token and expiration from localStorage
            const token = localStorage.getItem("token");
            const tokenExpiry = localStorage.getItem("token_expiry");

            if (!token || !tokenExpiry) {
                navigate("/login"); // Redirect to login if token is missing or expired
                return;
            }

            // Check if token is expired
            if (new Date().getTime() > new Date(tokenExpiry).getTime()) {
                // If expired, redirect to login
                localStorage.removeItem("token");
                localStorage.removeItem("token_expiry");
                navigate("/login");
                return;
            }
        };
        checkToken();
    }, [navigate]);

    // Render children if token is valid
    return <>{children}</>;
};

export default TokenCheck;
