import React from "react";
import Settings from "../components/api/Settings";
import TokenCheck from "../components/utils/TokenCheck";

const SettingsPage = () => {
    return (
        <div className='rf-home'>
            <TokenCheck>
                <Settings />
            </TokenCheck>
        </div>
    );
};

export default SettingsPage;
