import React, { useState } from "react";
import { Box, Button, FormControl, InputLabel, OutlinedInput, Typography, Alert } from "@mui/material";
import axios from "axios";

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [statusSeverity, setStatusSeverity] = useState("info");

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post("https://resurgence-api.xyz/util/forgot-password", { email });
            setStatusMessage("Password reset email sent successfully.");
            setStatusSeverity("success");
        } catch (error) {
            setStatusMessage("Error sending password reset email.");
            setStatusSeverity("error");
        }
    };

    return (
        <Box
            component='form'
            onSubmit={handleSubmit}
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "transparent",
                color: "white",
                padding: 2,
                borderRadius: 1,
                maxWidth: 400,
                margin: "auto",
                minHeight: "90vh",
            }}
        >
            <Typography
                variant='h2'
                fontSize='30px'
                style={{ borderBottom: "2px solid white", marginBottom: "1%", color: "white", textAlign: "center" }}
            >
                Forgot Password
            </Typography>
            <FormControl
                fullWidth
                variant='outlined'
                sx={{
                    marginBottom: 2,
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "white",
                        },
                        "&:hover fieldset": {
                            borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "white",
                        },
                        "& input": {
                            color: "white",
                        },
                    },
                    "& .MuiInputLabel-root": {
                        color: "white",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                    },
                    "& .MuiOutlinedInput-input": {
                        color: "white",
                    },
                }}
            >
                <InputLabel htmlFor='email'>Email</InputLabel>
                <OutlinedInput
                    id='email'
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label='Email'
                />
            </FormControl>
            <Button
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                sx={{
                    backgroundColor: "white",
                    color: "black",
                    "&:hover": {
                        backgroundColor: "white",
                    },
                }}
            >
                Send Reset Link
            </Button>
            {statusMessage && (
                <Alert severity={statusSeverity} sx={{ marginTop: 2 }}>
                    {statusMessage}
                </Alert>
            )}
        </Box>
    );
};

export default ForgotPasswordForm;
