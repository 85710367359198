import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Alert,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Modal,
} from "@mui/material";
import "../../styling/App.css";
import { formatDate } from "../utils/DateFormatUtil";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import StatusButton from "../utils/TableStatusCheck";

const BookingSystem = () => {
    const [events, setEvents] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState("");
    const [bookingStatus, setBookingStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();

    const handleOpenModal = (booking) => {
        setSelectedBooking(booking);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedBooking(null);
    };

    const fetchBookings = async () => {
        try {
            const token = localStorage.getItem("token");
            const userid = localStorage.getItem("userId");
            const response = await axios.get(`https://resurgence-api.xyz/bookings/byuser/${userid}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const bookings = response.data;
            const sortedBookings = bookings.sort((a, b) => b.id - a.id);
            setBookings(sortedBookings);
        } catch (error) {}
    };

    const fetchEvents = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get("https://resurgence-api.xyz/events", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setEvents(response.data);
        } catch (error) {}
    };

    useEffect(() => {
        fetchEvents();
        fetchBookings();
    }, []);

    const makeBooking = async (userId, selectedEvent, token) => {
        try {
            const bookingCheck = await axios.get(`https://resurgence-api.xyz/bookings/${userId}/${selectedEvent}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // If the request is successful (status code 2xx)
            var dataCheck = bookingCheck.data.status.toLowerCase();
            const status = dataCheck.trim();

            if (status !== "cancelled") {
                setBookingStatus("Booking already exists.");
            } else {
                processBooking(userId, token);
                // Delay in milliseconds
                const delay = 1000;

                setTimeout(() => {
                    window.location.reload();
                }, delay);
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 404) {
                    processBooking(userId, token);
                    const delay = 1000;

                    setTimeout(() => {
                        window.location.reload();
                    }, delay);
                } else {
                    setBookingStatus("Error checking booking status.");
                }
            } else if (error.request) {
                setBookingStatus("Error checking booking status. No response received.");
            } else {
                setBookingStatus("Error checking booking status.");
            }
        } finally {
            setLoading(false);
        }
    };

    const processBooking = async (userId, token) => {
        // Prepare booking data
        const bookingData = {
            userid: userId, // Use userId from localStorage
            eventid: selectedEvent,
            bookingdate: new Date().toISOString().slice(0, 19).replace("T", " "), // Convert to MySQL timestamp format
            status: "pending",
        };

        // Post booking data with Authorization header
        await axios.post("https://resurgence-api.xyz/bookings", bookingData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        setBookingStatus("Booking successful!");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedEvent) {
            setBookingStatus("Please select an event");
            return;
        }

        setLoading(true);

        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");

        await makeBooking(userId, selectedEvent, token);
    };

    const handleCancel = async (user_id, event_id) => {
        try {
            const token = localStorage.getItem("token");
            const bookingData = {
                status: "cancelled",
            };

            await axios.put(`https://resurgence-api.xyz/bookings/${user_id}/${event_id}`, bookingData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchBookings(); // Refresh events list
        } catch (error) {}
    };

    return (
        <div className='rf-booking-system' style={{ minHeight: "90vh" }}>
            <Box className='r' sx={{ mx: "auto", color: "white", borderRadius: 1, mt: 4 }}>
                <Typography
                    variant='h2'
                    class='rf-title-content'
                    fontSize='x-large'
                    style={{ borderBottom: "2px solid white", marginBottom: "1%" }}
                >
                    Make a booking
                </Typography>
                <form onSubmit={handleSubmit}>
                    <FormControl
                        fullWidth
                        margin='normal'
                        variant='outlined'
                        sx={{
                            "& .MuiInputLabel-root": {
                                color: "white",
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "white",
                                },
                                "&:hover fieldset": {
                                    borderColor: "white",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "white",
                                },
                            },
                            "& .MuiSelect-icon": {
                                color: "white",
                            },
                            "& .MuiInputBase-input": {
                                color: "white",
                            },
                            "& .MuiMenuItem-root": {
                                color: "white",
                            },
                        }}
                    >
                        <InputLabel id='event-select-label'>Event</InputLabel>
                        <Select
                            labelId='event-select-label'
                            id='eventSelect'
                            value={selectedEvent}
                            onChange={(e) => setSelectedEvent(e.target.value)}
                            label='Event'
                        >
                            <MenuItem value=''>
                                <em>Events --</em>
                            </MenuItem>
                            {events.map((event) => (
                                <MenuItem
                                    key={event.id}
                                    value={event.id}
                                    sx={{ fontSize: isMobile ? "0.70rem" : "1rem" }}
                                >
                                    {event.title + " | " + formatDate(event.event_date)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        fullWidth
                        sx={{
                            mt: 2,
                            backgroundColor: "white",
                            color: "black",
                            "&:hover": {
                                backgroundColor: "white",
                            },
                        }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : "Book Event"}
                    </Button>
                </form>
                {bookingStatus && (
                    <Alert severity={bookingStatus === "Booking successful!" ? "success" : "error"} sx={{ mt: 2 }}>
                        {bookingStatus}
                    </Alert>
                )}
            </Box>
            <Box sx={{ mx: "auto", color: "white", borderRadius: 1, mt: 4 }}>
                <Typography
                    variant='h2'
                    class='rf-title-content'
                    fontSize='x-large'
                    style={{ borderBottom: "2px solid white" }}
                >
                    My bookings
                </Typography>
                <TableContainer component={Paper} sx={{ backgroundColor: "transparent", minHeight: "50vh" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: "white", fontSize: "medium" }}>Event</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "medium" }}>Date</TableCell>
                                {!isMobile && (
                                    <>
                                        <TableCell sx={{ color: "white", fontSize: "medium" }}>Description</TableCell>
                                        <TableCell sx={{ color: "white", fontSize: "medium" }}>Location</TableCell>
                                    </>
                                )}
                                <TableCell sx={{ color: "white", fontSize: "medium" }}>Status</TableCell>
                                <TableCell sx={{ color: "white", fontSize: "medium" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bookings.map((booking) => (
                                <TableRow key={booking.id}>
                                    <TableCell sx={{ fontSize: isMobile ? "0.70rem" : "1rem", color: "white" }}>
                                        {booking.title}
                                    </TableCell>
                                    <TableCell sx={{ color: "white" }}>{formatDate(booking.event_date)}</TableCell>
                                    {!isMobile && (
                                        <>
                                            <TableCell sx={{ color: "white" }}>{booking.description}</TableCell>
                                            <TableCell sx={{ color: "white" }}>{booking.location}</TableCell>
                                        </>
                                    )}
                                    <TableCell sx={{ color: "white" }}>
                                        <StatusButton status={booking.status} />
                                    </TableCell>

                                    <TableCell sx={{ color: "white" }}>
                                        {isMobile && (
                                            <Button
                                                size='small'
                                                onClick={() => handleOpenModal(booking)}
                                                sx={{ color: "white", borderColor: "white" }}
                                            >
                                                Info
                                            </Button>
                                        )}
                                        {booking.status !== "cancelled" && booking.status !== "rejected" && (
                                            <Button
                                                size='small'
                                                onClick={() => handleCancel(booking.user_id, booking.event_id)}
                                                sx={{ color: "white", borderColor: "white" }}
                                            >
                                                Cancel
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Modal for additional info on mobile */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 300,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        color: "black",
                    }}
                >
                    {selectedBooking && (
                        <>
                            <Typography variant='h6' gutterBottom>
                                {selectedBooking.title}
                            </Typography>
                            <Typography variant='body2' gutterBottom>
                                <strong>Description:</strong> {selectedBooking.description}
                            </Typography>
                            <Typography variant='body2' gutterBottom>
                                <strong>Location:</strong> {selectedBooking.location}
                            </Typography>
                            <Typography variant='body2' gutterBottom>
                                <strong>Date:</strong> {formatDate(selectedBooking.event_date)}
                            </Typography>
                            <Typography variant='body2' gutterBottom>
                                <strong>Status:</strong> {selectedBooking.status}
                            </Typography>
                        </>
                    )}
                    <Button onClick={handleCloseModal} sx={{ mt: 2 }}>
                        Close
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default BookingSystem;
