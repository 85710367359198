import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AdminCheck = ({ children }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAdmin = () => {
            const isAdmin = localStorage.getItem("isAdmin") === "true"; // Adjust if necessary based on your storage format
            if (!isAdmin) {
                navigate("/unauthorized"); // Redirect to an unauthorized page or login if not admin
                return;
            }

            setLoading(false);
        };
        checkAdmin();
    }, [navigate]);

    if (loading) {
        return <div>Loading...</div>; // Or any loading indicator you prefer
    }

    return <>{children}</>;
};

export default AdminCheck;
