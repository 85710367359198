import React from "react";
import TokenCheck from "../components/utils/TokenCheck";
import AdminCheck from "../components/utils/AdminCheck";
import UserAdmin from "../components/admin/UserAdmin";

const AdminUser = () => {
    return (
        <div className='rf-home'>
            <TokenCheck>
                <AdminCheck>
                    <UserAdmin />
                </AdminCheck>
            </TokenCheck>
        </div>
    );
};

export default AdminUser;
