import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
                customBreakpoint: 800, // Custom breakpoint example
            },
        },
        primary: {
            light: "#757ce8",
            main: "#111020",
            dark: "#002884",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ff7961",
            main: "#f44336",
            dark: "#ba000d",
            contrastText: "#000",
        },
    },
    typography: {
        fontFamily: ["Nunito" /* Default Material-UI font */, "Arial" /* Backup font */, "sans-serif"].join(","),
        // You can also define specific styles for different typography variants
        h1: {
            fontFamily: "Arial" /* Example: Custom font for h1 */,
            fontWeight: 700 /* Example: Custom font weight */,
            fontSize: "2.5rem" /* Example: Custom font size */,
        },
        // Add more typography variants as needed
    },
});

export default theme;
