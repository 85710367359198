import React from "react";
import PasswordResetForm from "../components/api/ResetPassword";

const Reset = () => {
    return (
        <div className='rf-home'>
            <PasswordResetForm />
        </div>
    );
};

export default Reset;
